<template>
  <div>
    <div class="region-search">
      <el-select v-model="addressData.province" placeholder="省份/自治区/直辖市" @change="changProvinve" size="small" clearable>
        <el-option v-for="item in provinceList" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-select :disabled="!addressData.province" v-model="addressData.city" placeholder="市/州" @change="changeCity" size="small" clearable>
        <el-option v-for="item in cityList" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-select :disabled="!addressData.city" v-model="addressData.district" placeholder="区/县" @change="changeDistrict" size="small" clearable>
        <el-option v-for="item in districtList" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import api from '../lib/api/region'
export default {
  props: ['province', 'city', 'district'],
  data () {
    return {
      addressData: {
        province: null,
        city: null,
        district: null
      },
      provinceList: [],
      cityList: [],
      districtList: []
    }
  },
  watch: {
    'province': function (val) {
      this.addressData.province = val
      // console.log('p', this.addressData)
    },
    'city': function (val) {
      this.addressData.city = val
      // console.log('c', this.addressData)
    },
    'district': function (val) {
      this.addressData.district = val
      // console.log('d', this.addressData)
    },
    'addressData.province': function () {
      console.log('aaa')
      this.selectCity()
      this.$emit('update:province', this.addressData.province)
    },
    'addressData.city': function () {
      console.log('bbb')
      this.selectDistrict()
      this.$emit('update:city', this.addressData.city)
    },
    'addressData.district': function () {
      console.log('ccc')
      this.$emit('update:district', this.addressData.district)
    }
  },
  mounted () {
    this.selectProvince()
    let { province, city, district } = this
    if (province) this.$set(this.addressData, 'province', province)
    if (city) this.$set(this.addressData, 'city', city)
    if (district) this.$set(this.addressData, 'district', district)
  },
  methods: {
    selectProvince () {
      api.getRegion({ code: '00' }).then((res) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.provinceList = res.data.map(e => {
          let label = e.provinceName
          let value = e.provinceCode
          return { label, value }
        })
        // console.log(this.provinceList)
      }).catch(() => {
      })
    },
    selectCity () {
      let code = this.addressData.province
      api.getRegion({ code }).then((res) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.cityList = res.data.map(e => {
          let label = e.cityName
          let value = e.cityCode
          return { label, value }
        })
        // console.log(this.cityList)
      }).catch(() => {
      })
    },
    selectDistrict () {
      let code = this.addressData.city
      api.getRegion({ code }).then((res) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.districtList = res.data.map(e => {
          let label = e.districtName
          let value = e.districtCode
          return { label, value }
        })
        // console.log(this.districtList)
      }).catch(() => {
      })
    },
    changProvinve (val) {
      let row = this.provinceList.find((v) => v.value === val)
      if (row) this.$emit('update:provinceName', row.label)
      if (this.addressData.city) {
        this.$set(this.addressData, 'city', null)
        this.$set(this.addressData, 'district', null)
        this.$emit('update:cityName', '')
        this.$emit('update:districtName', '')
      } else if (this.addressData.district) {
        this.$set(this.addressData, 'district', null)
        this.$emit('update:districtName', '')
      }
    },
    changeCity (val) {
      let row = this.cityList.find((v) => v.value === val)
      if (row) this.$emit('update:cityName', row.label)
      if (this.addressData.district) {
        this.$set(this.addressData, 'district', null)
        this.$emit('update:districtName', '')
      }
    },
    changeDistrict (val) {
      let row = this.districtList.find((v) => v.value === val)
      if (row) this.$emit('update:districtName', row.label)
    }
  }
}
</script>

<style lang="scss">
.region-search{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .el-select {
    margin: 0;
    flex-grow: 1;
    width: 100%;
    & + .el-select {
      margin-left: 10px;
    }
  }
  
}

</style>
