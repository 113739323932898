export const tableItems = [
  {
    key: 'id',
    label: '账套ID',
    hide: true,
    primary: true
  }, {
    key: 'code',
    label: '仓库编号',
    width: 100,
    component: 'slot',
  }, {
    key: 'name',
    label: '仓库名称',
    component: 'input',
    showOverflowTooltip: true,
    minWidth: 180,
    rules: [
      { required: true, message: '请填写仓库名称', trigger: 'blur' },
      { pattern: /^\S{2,200}$/, message: '请正确填写仓库名称', trigger: 'blur' }
    ]
  }, {
    key: 'typeId',
    label: '仓库类型',
    hideOnView: true,
    component: 'slot',
    span: 12,
    rules: [
      { required: true, message: '请选择仓库类型', trigger: 'change' }
    ]
  }, {
    key: 'typeName',
    label: '仓库类型',
    hideOnEdit: true
  }, {
    key: 'tagNameList',
    label: '标签',
    hideOnEdit: true,
    hideOnView: false,
    slot: true,
    component: 'slot',
    width: 166
  }, {
    key: 'statusId',
    label: '状态',
    hideOnView: true,
    span: 12,
    component: {
      type: 'switch',
      activeValue: '1',
      inactiveValue: '2'
    }
  }, {
    key: 'tag',
    label: '标签',
    hideOnView: true,
    component: 'slot'
  }, {
    key: 'area',
    label: '所属区域',
    hideOnEdit: true,
    showOverflowTooltip: true,
    minWidth: 180
  }, {
    key: 'districtCode',
    label: '所属区域',
    hideOnView: true,
    component: 'slot',
    rules: [
      { required: true, message: '请选择仓库所在地区', trigger: 'change' }
    ]
  }, {
    key: 'address',
    label: '仓库地址',
    component: 'input',
    showOverflowTooltip: true,
    minWidth: 180,
    rules: [
      { required: true, message: '请填写仓库地址', trigger: 'blur' },
      { pattern: /^\S{2,200}$/, message: '请正确填写仓库地址', trigger: 'blur' }
    ]
  }, {
    key: 'manager',
    label: '负责人',
    component: 'input',
    rules: []
  }, {
    key: 'phone',
    label: '联系方式',
    component: 'input',
    width: 120,
    rules: [
      { required: true, message: '请填写联系方式', trigger: 'blur' },
      { pattern: /^1[3-9][0-9]{9}$/, message: '请正确填写联系方式', trigger: 'blur' }
    ]
  }, {
    key: 'statusId',
    label: '状态',
    width: 100,
    slot: true,
    component: 'slot',
    hideOnEdit: true
  }, {
    key: 'remark',
    label: '备注',
    showOverflowTooltip: true,
    minWidth: 180,
    component: {
      type: 'textarea',
      rows: 2,
      placeholder: '请输入备注'
    },
    rules: []
  }, {
    key: 'creatorName',
    label: '创建人',
    hideOnEdit: true,
    rules: []
  }, {
    key: 'createDate',
    label: '创建时间',
    width: 160,
    hideOnEdit: true,
    rules: []
  }, {
    type: 'actions',
    label: '操作',
    width: 150,
    fixed: 'right'
  }
]