<template>
<div>
  <mts-table
    v-model="pageItems" :pager="{ 'current-page': current, 'page-size':size, total }"
    :loading.sync="dataListLoading" :config="tableConfig"
    @pager:size-change="sizeChanged"
    @pager:current-change="pageChanged">
    <template #tableHeader>
      <el-form class="searchForm" inline>
        <el-form-item>
          <el-select v-model="searchForm.typeId" placeholder="仓库类型" class="item">
            <el-option v-for="item in typeItems" :label="item.name" :key="item.id" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="regionSearchBox">
          <region-search :province.sync="searchForm.provinceCode" :city.sync="searchForm.cityCode" :district.sync="searchForm.districtCode"></region-search>
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchForm.keyWord" placeholder="请输入关键词" clearable size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="searchDataList()" size="small" type="primary">搜索</el-button>
          <el-button size="small" @click="resetSearch">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button @click="showEditWin()" type="primary">新增仓库</el-button>
    </template>
    <template #tagNameList="{ row }">
      <div><el-tag class="tagClass" v-for="(item,index) in row.tagNameList" disable-transitions :key="index">{{item}}</el-tag></div>
    </template>
    <template #statusId="{ row }">
      <el-switch :value="row.statusId" active-value="1" inactive-value="2" @change="(statusId) => updateItemStatus(row, { statusId })"></el-switch>
    </template>
    <template #tableActions="{ row }">
      <el-button type="text" @click.stop="showDetailWin(row)">详情</el-button>
      <el-button type="text" @click.stop="showEditWin(row)">编辑</el-button>
      <el-button type="text" :disabled="row.statusId === '1'" @click.stop="deleteHandle(row.id)">删除</el-button>
    </template>
  </mts-table>
  <el-dialog
    :title="dialogTitle"
    :visible="winVisible"
    @close="winVisible = false"
    width="300"
  >
    <mts-form v-model="form" :config="formConfig" ref="dialogForm">
      <template #code="{ form }">
        <el-input v-model="form.code" placeholder="未填写系统可自动生成" :disabled="!!form.id"></el-input>
      </template>
      <template #typeId="{ form }">
        <el-select v-model="form.typeId" placeholder="请选择仓库类型" class="fullWidth">
          <el-option v-for="item in typeItems" :label="item.name" :key="item.id" :value="item.id"></el-option>
        </el-select>
      </template>
      <template #tag="{ form }">
        <div>
          <el-tag class="marginRight" v-for="(item,index) in form.tagNameList" closable :key="index" @close="tagClose(index)">{{item}}</el-tag>
          <el-select v-model="form.tagId" size="small" placeholder="请选择标签" filterable default-first-option allow-create @change="tagChange" >
            <el-option v-for="(item, idx) in tagList" :key="idx" :value="item.id" :label="item.name" :disabled="isSelect(item.name)"></el-option>
          </el-select>
        </div>
      </template>
      <template #districtCode="{ form }">
        <region-search :province.sync="form.provinceCode" :city.sync="form.cityCode" :district.sync="form.districtCode"></region-search>
      </template>
    </mts-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="winVisible = false">取消</el-button>
      <el-button type="primary" @click="submitHandle()">确认</el-button>
    </span>
  </el-dialog>
  <el-dialog
    title="查看仓库资料" width="300"
    :visible="detailWinVisible"
    @close="detailWinVisible = false">
    <mts-form v-model="form" :config="detailConfig" ref="detailForm" class="viewFormWin">
      <template #code="{ form }">{{ form.code }}</template>
      <template #tagNameList="{ form }">{{form.tagNameList.join(',')}}</template>
      <template #statusId="{ form }">{{ form.statusName }}</template>
    </mts-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="detailWinVisible = false">关闭</el-button>
    </span>
  </el-dialog>
</div>
</template>
<script>
import { tableItems } from './tenant.config'
import { mapState, mapActions } from 'vuex'
import mtsTable from '@/components/mts/table'
import mtsForm from '@/components/mts/form'
import regionSearch from '@/components/regionSearch'
import _ from 'lodash'


export default {
  data () {
    return {
      tableConfig: {
        tableProps: {
          border: true
        },
        items: tableItems
      },
      searchForm: {
        cityCode: null,
        districtCode: null,
        keyWord: '',
        provinceCode: null,
        typeId: null
      },
      dataListLoading: false,
      winVisible: false,
      form: {
        tagNameList: []
      },
      detailWinVisible: false,
      tagList: [],
    }
  },
  components: {
    mtsTable, mtsForm, regionSearch
  },
  activated () {
    this.statusList()
    this.typeList()
    this.getTagList()
    this.getDataList()
  },
  computed: {
    ...mapState('tenant', ['current', 'size', 'total', 'pageItems', 'statusItems', 'typeItems']),
    formConfig () {
      return {
        items: tableItems,
        props: {
          labelWidth: '120px'
        }
      }
    },
    detailConfig () {
      let arr = tableItems.filter((v) => {
        if (v.hide) {
          return false
        } else {
          return !v.hideOnView
        }
      })
      let items = _.cloneDeep(arr)
      items.forEach((v) => {
        if (v.component !== 'slot') {
          v.component = 'static'
        }
        delete v.rules
        if (v.hideOnEdit) delete v.hideOnEdit
      })

      return {
        items,
        props: {
          labelWidth: '120px'
        }
      }
    },
    dialogTitle () {
      if (this.form && this.form.id) {
        return '修改仓库'
      } else {
        return '新增仓库'
      }
    }
  },
  methods: {
    ...mapActions('tenant', [ 'page', 'delete', 'save', 'modify', 'detail', 'statusList', 'typeList', 'updateStatus' ]),
    sizeChanged (size) {
      this.getDataList(1, size)
    },
    pageChanged (page) {
      this.getDataList(page)
    },
    searchDataList () {
      this.getDataList(1)
    },
    resetSearch () {
      let form = this.$options.data().searchForm
      this.$set(this, 'searchForm', form)
      this.$nextTick(() => {
        this.getDataList(1)
      })
    },
    getDataList (p, s) {
      if (!p) p = this.current
      if (!s) s = this.size
      this.dataListLoading = true
      let params = {
        page: p,
        limit: s,
        ...this.searchForm
      }
      return this.page(params).then(() => {
        this.dataListLoading = false
      }).catch((e) => {
        this.dataListLoading = false
        this.$message.error(e.message)
      })
    },
    updateItemStatus (row, data) {
      this.$confirm('确认修改此项的状态？', '修改仓库状态', {
        confirmButtonText: '提交',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let form = _.cloneDeep(data)
        form.id = row.id
        this.updateStatus(form).then(() => {
          this.getDataList()
          this.$message.success('修改仓库状态成功')
        }).catch((e) => {
          console.error(e)
          this.$message.error(e.message)
        })
      })
    },
    deleteHandle (ids) {
      if (!ids) {
        this.$message.warning('请选择需要删除的项目')
        return
      }
      if (!Array.isArray(ids)) ids = [ ids ]
      this.$confirm('确认要删除这个项目？', '删除仓库', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delete(ids).then(() => {
          this.getDataList()
          this.$message.success('删除仓库成功')
        }).catch((e) => {
          console.error(e)
          this.$message.error(e.message)
        })
      })
    },
    // 新增 / 修改
    showEditWin (row) {
      let { form } = this.$options.data()
      console.log(this.form)
      if (row && row.id) {
        this.detail(row.id).then((json) => {
          form = _.cloneDeep(json.data)
          this.$set(this, 'form', form)
          this.$set(this.form, 'tagId', '')
          this.winVisible = true
        })
      } else {
        this.$set(this, 'form', form)
        this.$set(this.form, 'tagId', '')
        this.winVisible = true
      }
      
    },
    showDetailWin (row) {
      if (!row || !row.id) return
      this.detail(row.id).then((json) => {
        let form = _.cloneDeep(json.data)
        this.$set(this, 'form', form)
        this.detailWinVisible = true
      })
    },
    // 获取标签数据
    getTagList () {
      this.$http.get('/tenant/tag', {
        params: {
          page: 1,
          limit: 1e6
        }
      }).then(({ data: res }) => {
        let { code, msg, data } = res
        // console.log(code, msg, data)
        if (code === 0) {
          this.tagList = data.records
        } else {
          this.$message.error(msg)
        }
      })
    },
    // 标签被修改
    tagChange (val) {
      console.log(val)
      console.log(val.indexOf('|'))
      if (val.indexOf('|') !== -1) {
        this.$message.warning('标签中不能带有|符号')
        return -1
      }
      // console.log(arguments)
      let ret = this.tagList.find((v) => v.id === val)
      if (!ret) {
        this.tagList.push({
          name: val,
          id: val
        })
        console.log(this.form)
        this.form.tagNameList.push(val)
      } else {
        console.log(this.form)
        this.form.tagNameList.push(ret.name)
      }
      this.$set(this.form, 'tagId', '')
    },
    isSelect (val) {
      let found = this.form.tagNameList.find(item => item === val)
      return !!found
    },
    submitHandle () {
      let form = _.cloneDeep(this.form)
      let func = 'save'
      if (form.id) {
        func = 'modify'
      }
      this.$refs.dialogForm.validate().then(() => this[func](form)).then(() => {
        this.$message.success(this.dialogTitle + '成功')
        this.getDataList(1)
        this.winVisible = false
      }).catch((e) => {
        this.$alert(e.message, this.dialogTitle, { type: 'error' })
      })
    },
    tagClose (index) {
      this.form.tagNameList.splice(index, 1)
    }
  }
}
</script>
<style lang="scss" scoped>
.searchForm .regionSearchBox {
  display: inline-flex;
  width: 600px;
}
.tagClass {
  margin-right: 6px;
  margin-bottom: 6px;
}
.marginRight {
  margin-right: 6px;
}
</style>
